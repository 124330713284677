<template>
    <div class="MethodInfoBoxOuter">
        <v-btn elevation="0" class="content-btn info ml-4" :class="{'inactive': noMethod}" @click="$emit('methodInfoModalOpen')"></v-btn>
        <div class="MethodInfoBox" v-if="methodInfoModal">
            <div class="cancel" @click="$emit('methodInfoModalClose')"></div>
            <div class="container pa-6">
                <h2 class="pb-2">{{ selectedMethod.methodName }}</h2>
                <p class="pb-2">{{ $t('methods.testingTime') }}: {{ timeFormat(selectedMethod.testingTime)}}</p>
                <p class="pb-2" v-if="selectedMethod.maxTestingTimeStatus">{{ $t('methods.maxTestingTime') }}: {{ timeFormat(selectedMethod.maxTestingTime)}}</p>
                <p class="pb-2" v-else>{{ $t('methods.maxTestingTime') }}: OFF</p>
                <p class="pb-2">{{ $t('methods.selectMedia') }}: {{ findMedia(selectedMethod.media)}}</p>
                <p class="pb-2">{{ $t('methods.selectBasket') }}: {{ selectedMethod.basket}}</p>
                <p class="pb-2" v-if="selectedMethod.combinationTest">{{ $t('methods.combinationTest') }}: ON</p>
                <p class="pb-2" v-else>{{ $t('methods.combinationTest') }}: OFF</p>
                <p class="pb-2">{{ $t('methods.thresholdHeight') }}: {{ selectedMethod.threshold}} mm</p>
                <p class="pb-2" v-if="selectedMethod.temperatureStatus">{{ $t('methods.temperatureLimits') }}: {{ $t('general.from') }} {{ selectedMethod.temperatureMin}} {{ $t('general.to') }} {{ selectedMethod.temperatureMax}} °C</p>
                <p class="pb-2" v-else>{{ $t('methods.temperatureLimits') }}: OFF</p>
                <p class="pb-2" v-if="selectedMethod.pretestStatus">{{ $t('methods.pretest') }}: ON</p>
                <p class="pb-2" v-else>{{ $t('methods.pretest') }}: OFF</p>
                <p class="pb-2" v-if="selectedMethod.pretestStatus">{{ $t('methods.pretestMedia') }}: {{ selectedMethod.pretestMedia}}</p>
                <p class="pb-2" v-if="selectedMethod.pretestStatus">{{ $t('methods.pretestTestingTime') }}: {{ timeFormat(selectedMethod.pretestTestingTime)}}</p>
                <p class="pb-2" v-if="selectedMethod.assessmentStatus">{{ $t('methods.assessment') }}: {{ $t('methods.minDisintegrated') }} {{ selectedMethod.assessmentMinDisintegrated}} {{ $t('general.of') }} {{ selectedMethod.assessmentMaxDisintegrated}} </p>
                <p class="pb-2" v-else>{{ $t('methods.assessment') }}: OFF</p>
                <p class="pb-2" v-if="selectedMethod.fastDisintegration">{{ $t('methods.fastDisintegration') }}: ON</p>
                <p class="pb-2" v-else>{{ $t('methods.fastDisintegration') }}: OFF</p>
                <p class="pb-2" v-if="selectedMethod.withoutDisc">{{ $t('methods.withoutDisc') }}: ON</p>
                <p class="pb-2" v-else>{{ $t('methods.withoutDisc') }}: OFF</p>
<!--                <p v-if="selectedMethod.manual">{{ $t('methods.manual') }}: ON</p>-->
<!--                <p v-else>{{ $t('methods.manual') }}: OFF</p>-->
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
  name: "MethodInfoBox",
    props: {
        methodInfoModal:{
            type: Boolean,
            default: false
        },
        state: {
            type: String
        }
    },
    computed: {
        ...mapState([
            'editProduct',
            'medialist'
        ]),
        ...mapGetters({
            activeMethods: 'activeMethods',
        }),
        fromState(){
            return this.$store.state[this.state];
        },
        selectedMethod() {
            let method;
            let methodID = this.fromState.method;
            if (methodID != null){
                method = this.activeMethods.find(function(element){
                    return element.id == methodID;
                });
            }
            return method;
        },
        noMethod(){
            let methodID = this.fromState.method;
            if (methodID != null){
                let method = this.activeMethods.find(function(element){
                    return element.id == methodID;
                });
                if (method){
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
    },
    methods: {
        findMedia(id){
            let mediaName = this.medialist.find(function(media){
                return media.id == id;
            });
            if (mediaName){
                mediaName = mediaName.name;
            } else {
                mediaName = '–'
            }
            return mediaName;
        },
    },
    mixins: [mixins],
}
</script>

<style scoped lang="scss">
.content-btn.info{
    height: 52px;
    min-width: 52px;
    max-width: 52px;
    background-color: $disiIrisblue!important;
    &::after{
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("../assets/info_weiss.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: none;
    }

    &.inactive{
        background-color: $disiBluegreyMedium!important;
        pointer-events: none;
    }
}
.MethodInfoBox{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba($disiBluegreyLight, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    .cancel{
        position: absolute;
        top: 5%;
        right: 13%;
        width: 52px;
        height: 52px;
        background-color: $disiIrisblue;
        background-image: url("../assets/cancel_weiss.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
    }

    .container{
        background-color: white;
        width: 60%;
        height: 90%;
        box-shadow: 0 0 15px 0 $disiBluegreyDark;
        overflow: scroll;
    }
    h2{
        font-size: 1.2rem;
    }
    p{
        font-size: 0.85rem;
    }
}
</style>