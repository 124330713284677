<template>
    <div class="ProductTest full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <navigation-left-small noBackButton TestBack disabled/>
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('productTest.selectProduct') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-select :items="activeProductNamesWithNumber" v-model="product"
                                      class="productTest-product-dropdown" outlined hide-details="auto" attach
                                      :rules="[v => !!v || $t('validation.required')]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('productTest.selectMethod') }}</p>
                        </v-col>
                        <v-col class="col-10 d-flex">
                            <v-select :items="activeMethods" item-text="methodName" item-value="id"
                                      v-model="productTest.method" class="productTest-method-dropdown" outlined
                                      hide-details="auto" attach :rules="[rules.method]"
                                      :disabled="!checkUserRights('productTestMethod')"/>
                            <MethodInfoBox :method-info-modal="methodInfoModal" state="productTest"
                                           @methodInfoModalClose="methodInfoModal = false"
                                           @methodInfoModalOpen="methodInfoModal = true"></MethodInfoBox>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': !productTest.product}">
                            <p>{{ $t('productTest.selectBatch') }}</p>
                        </v-col>
                        <v-col class="col-4">
                            <v-select :items="batchNames" item-text="name" item-value="id" v-model="productTest.batch"
                                      :disabled="!productTest.product" class="productTest-batch-dropdown" outlined
                                      hide-details="auto" attach
                                      :rules="[v => v != null || $t('validation.required')]"/>
                        </v-col>
                        <v-col class="col-2 pl-4" :class="{'disabled': newBatch || !productTest.product}">
                            <p>{{ $t('quickTest.nameNewBatch') }}</p>
                        </v-col>
                        <v-col class="col-4">
                            <text-input inputName="productTest-newBatch" @onInputFocus="onInputFocus"
                                        :disabled="newBatch || !productTest.product"
                                        :rules="[rules.maxLengthName, rules.alphanumericSpecCharValue, rules.batchname]"/>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToDetailPage"></footer-button>
            <footer-button buttonFunction="start" @footerButtonClick="startProductTest"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import NavigationLeftSmall from '@/components/NavigationLeftSmall.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapState, mapGetters} from "vuex";
import TextInput from "@/components/TextInput.vue";
import mixins from "@/mixins/mixins";
import MethodInfoBox from "@/components/MethodInfoBox.vue";

export default {
    name: 'ProductTest',
    components: {
        NavigationLeftSmall,
        TextInput,
        MethodInfoBox,
        FooterButton,
    },
    props: {},
    data() {
        return {
            methodInfoModal: false,
            rules: {
                maxLengthName: v => {
                    if (v) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
                alphanumericSpecCharValue: v => {
                    if (v) {
                        let alphanumericSpecChar = /^[A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                method: v => {
                    if (v) {
                        let method = this.activeMethods.find(element => {
                            return element.id === v;
                        });
                        if (method) {
                            return true;
                        } else {
                            return `${this.$t('validation.selectActiveMethod')}`;
                        }
                    } else {
                        return `${this.$t('validation.required')}`;
                    }
                },
                batchname: v => {
                    if (this.productTest.batch == 0) {
                        if (v) {
                            return !!v;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'productTest',
            'productlist',
            'methodlist',
            'batchlist',
            'activeStationId',
        ]),
        ...mapGetters({
            activeProductNames: 'activeProductNames',
            activeProductNamesWithNumber: 'activeProductNamesWithNumber',
            activeMethods: 'activeMethods',
        }),
        product: {
            get() {
                let productNameAndNumber = "";
                let productId = this.productTest.product;
                if (productId > 0) {
                    let product = this.productlist.find(function (product) {
                        return product.id == productId;
                    });
                    productNameAndNumber = `${product.productName} (${product.number})`;
                }
                return productNameAndNumber;
            },
            set(productName) {
                let name = productName.split(' (')[0];
                let product = this.productlist.find(function (product) {
                    return product.productName == name;
                });
                this.$store.commit('updateProduct', product.id);
                this.$store.commit('updateMethod', product.method);
                this.$store.dispatch('postAxiosSetter', ['/disi/testing/batch/listByProduct', {productId: product.id}, 'batchlist']);
            }
        },
        batchNames() {
            let batchNames = [];
            let newBatch = {
                id: 0,
                name: this.$t('quickTest.newBatch')
            }
            batchNames.push(newBatch);
            this.batchlist.forEach(batch => {
                batchNames.push(batch);
            })
            return batchNames;
        },
        newBatch() {
            if (this.productTest.batch != 0 || this.productTest.batch == null) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        goToDetailPage() {
            this.$router.push('station-testdetail');
        },
        startProductTest() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.$confirm({message: this.$t('alerts.securityInfo'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('footerButton.start') })
                    .then(() => {
                        // continue
                        let newProductTest = JSON.parse(JSON.stringify(this.productTest));
                        newProductTest.station = this.activeStationId;
                        this.$store.dispatch('postAxiosNoSetter', ['disi/testing/product-test', newProductTest])
                            .then(response => {
                                if (response.status === 200) {
                                    this.goToDetailPage();
                                }
                            });
                    })
                    .catch(() => {
                        // cancel
                        this.$confirm({message: this.$t('alerts.securityInfo'), show: false, cancelText: this.$t('general.cancel'), continueText: this.$t('footerButton.start') })
                    });

            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/disi/methods/list', 'methodlist']);
        this.$store.dispatch('getAxiosSetter', ['/disi/products/list', 'productlist']);
        this.$store.commit('resetProductTest');
    },
}
</script>
<style scoped lang="scss">

</style>